import { Badge, Button, Chip, CircularProgress, circularProgressClasses, Skeleton } from "@mui/material"
import { ApiRequest } from "GlobalFunctions"
import { useEffect, useState, useRef } from "react"
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';

import { PiSeal } from "react-icons/pi";
import { PiSealCheckFill } from "react-icons/pi";
import { PiRocketLaunch } from "react-icons/pi";
import io from 'socket.io-client';
import { ProjectConfig } from "Global";

export default function Checklist() {

    const [checklist, setChecklist] = useState<any[]>([])
    const [loading, setLoading] = useState(false)
    const [completionPercentage, setCompletionPercentage] = useState(0)
    // Initialize isChecklistOpen from localStorage or default to true if not found
    const [isChecklistOpen, setIsChecklistOpen] = useState(() => {
        const savedState = localStorage.getItem('checklistOpen');
        return savedState === null ? true : savedState === 'true';
    })
    // Add state to track which item is being hovered
    const [hoveredItemIndex, setHoveredItemIndex] = useState<number | null>(null)
    const socketRef = useRef<any>(null);
    
    // Socket.IO connection
    useEffect(() => {
        // Get API URL from environment or use default
        const API_URL = ProjectConfig.api_url;
        const storeId = localStorage.getItem('store_id');
        
        if (!storeId) {
            return;
        }
        
        // Create socket connection
        socketRef.current = io(API_URL);
        
        // Listen for connection events
        socketRef.current.on('connect', () => {
            // Register with store ID for targeted notifications
            socketRef.current.emit('register', storeId);
        });
        
        // Listen for checklist update events
        socketRef.current.on('checklist-update', (data: any) => {
            // Refresh the checklist data
            // getChecklist(); 
        });
        
        // Clean up on unmount
        return () => {
            if (socketRef.current) {
                socketRef.current.disconnect();
            }
        };
    }, []);

    const getChecklist = () => {
        setLoading(true)
        ApiRequest({
            method: 'GET',
            url: '/stores/getChecklist',
            setResponse: (response: any) => {
                if (response && response.steps) {
                    setChecklist(response.steps)
                    
                    // Calculate completion percentage
                    const completedSteps = response.steps.filter((step: any) => step.completed).length
                    const totalSteps = response.steps.length
                    const percentage = Math.round((completedSteps / totalSteps) * 100)
                    setCompletionPercentage(percentage)
                    
                    // If completion is 100%, always keep checklist closed
                    if (percentage === 100) {
                        setIsChecklistOpen(false);
                        localStorage.setItem('checklistOpen', 'false');
                    }
                }
                setLoading(false)
            }
        })
    }

    // Function to toggle checklist item status
    const toggleChecklistItem = (index: number) => {
        
        // if (loading) {
        //     return;
        // }
        
        // Skip the first item (site connection) which can't be manually toggled
        if (index === 0) {
            return;
        }
        
        const updatedChecklist = [...checklist];
        const item = updatedChecklist[index];
        const newStatus = !item.completed;

        // Optimistically update UI
        updatedChecklist[index] = { ...item, completed: newStatus };
        setChecklist(updatedChecklist);
        
        // Calculate new completion percentage
        const completedSteps = updatedChecklist.filter(step => step.completed).length;
        const totalSteps = updatedChecklist.length;
        const percentage = Math.round((completedSteps / totalSteps) * 100);
        setCompletionPercentage(percentage);
        
        const storeId = localStorage.getItem('store_id');

        
        ApiRequest({
            method: 'POST',
            url: '/stores/updateChecklistItem',
            body: {
                index: index,
                completed: newStatus
            },
            headers: {
                'Content-Type': 'application/json' // Explicitly set content type to JSON
            },
            setResponse: (response: any) => {
                if (response && response.status === 'success') {
                    
                } else {
                    // Revert UI if update failed
                    // getChecklist();
                }
            }
        });
    }

    useEffect(() => {
        console.log("CHECKLIST OPEN");
        getChecklist()
    }, [])

    // Function to toggle checklist visibility and save to localStorage
    const toggleChecklist = () => {
        const newState = !isChecklistOpen;
        setIsChecklistOpen(newState);
        localStorage.setItem('checklistOpen', String(newState));
    }

    // Effect to handle 100% completion case
    useEffect(() => {
        if (completionPercentage === 100) {
            setIsChecklistOpen(false);
            localStorage.setItem('checklistOpen', 'false');
        }
    }, [completionPercentage]);

    return (
        <div className='onboarding_checklist_wrapper'>
            {isChecklistOpen && (
                <div className='onboarding_checklist'>
                    <div className='checklist_title'>
                        <h2>Get Started</h2>
                        <div className='checklist_progress'>
                            <div style={{ position: 'relative', display: 'inline-flex' }}>
                                <CircularProgress
                                    variant="determinate"
                                    sx={{
                                        color: '#7963ff20',
                                    }}
                                    size={20}
                                    thickness={10}
                                    value={100}
                                />
                                <CircularProgress
                                    variant="determinate" 
                                    sx={{
                                        color: '#7963ff',
                                        position: 'absolute',
                                        left: 0,
                                    }}
                                    size={20}
                                    thickness={10}
                                    value={completionPercentage}
                                />
                            </div>
                            <p className="checklist_percentage">{completionPercentage}%</p>
                        </div>
                    </div>

                    <div className='checklist_items'>
                        {loading ? (
                            // Skeleton loading for 7 checklist items
                            Array(7).fill(0).map((_, index) => (
                                <div key={index} className="checklist_item">
                                    <div className='checklist_item_status'>
                                        <Skeleton 
                                            variant="circular" 
                                            width={24} 
                                            height={24} 
                                            sx={{ bgcolor: '#7963ff20' }}
                                        />
                                    </div>
                                    <div className='checklist_item_text'>
                                        <Skeleton 
                                            variant="rounded" 
                                            height={24} 
                                            width={240}
                                            sx={{ bgcolor: '#7963ff20' }}
                                        />
                                    </div>
                                </div>
                            ))
                        ) : (
                            checklist.map((item: any, index: number) => (
                                <div 
                                    key={index} 
                                    className={`checklist_item ${item.completed ? 'completed' : ''}`}
                                    onClick={() => toggleChecklistItem(index)}
                                    style={{ cursor: 'pointer' }}
                                    onMouseEnter={() => setHoveredItemIndex(index)}
                                    onMouseLeave={() => setHoveredItemIndex(null)}
                                >
                                    <div className='checklist_item_status'>
                                        {item.completed ? (
                                            <PiSealCheckFill 
                                                color="#7963ff"
                                                size={24}
                                            />
                                        ) : (
                                            <PiSeal
                                                color="#7963ff"
                                                size={24}
                                                className={hoveredItemIndex === index ? '' : 'icon_hidden'}
                                            />
                                        )}
                                    </div>
                                    <div className='checklist_item_text'>
                                        {item.text}
                                    </div>
                                </div>
                            ))
                        )}
                    </div>
                </div>
            )}
            
            <div className='onboarding_checklist_button'>
                <Button
                    startIcon={<PiRocketLaunch color={isChecklistOpen ? '#fff' : '#a0a0a0'} />} 
                    variant='contained'
                    color={isChecklistOpen ? 'primary' : 'inherit'} 
                    onClick={toggleChecklist} 
                    disabled={loading}
                    size="small"
                    disableRipple={false}
                    sx={{ 
                        padding: '4px 4px 4px 4px',
                        '& .MuiButton-startIcon': { 
                            marginRight: '4px',  
                            marginLeft: '0px'
                        }
                    }}
                >
                    <Chip 
                        label={`${checklist.filter(item => item.completed).length}/${checklist.length}`} 
                        sx={{ 
                            bgcolor: 'white', 
                            color: 'primary.main',
                            height: '16px',
                            '& .MuiChip-label': {
                                padding: '0 6px',
                                fontSize: '13px',  // Reduced font size to make text smaller
                                fontWeight: 400,
                                lineHeight: 1,
                                color: isChecklistOpen ? 'primary.main' : '#a0a0a0', // Changed to gray color instead of text.secondary
                            }
                        }}
                    />
                </Button>
            </div>
        </div>
    )
}