import axios from 'axios';
import { useEffect, useState } from 'react';
import { Button, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { useSnackbar } from 'notistack';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { ProjectConfig } from 'Global';
import moment from 'moment';
import AddIcon from '@mui/icons-material/Add';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import 'assets/css/main.css';
import UsersEditor from './UsersEditor';
import EmptyElement from 'elements/EmptyElement';
import TuneIcon from '@mui/icons-material/Tune';
import ListPagination from 'elements/ListPagination';
import { ApiRequest } from 'GlobalFunctions';
import LoadingButton from '@mui/lab/LoadingButton';

export default function OrdersList(props: any) {
  const [open, setOpen] = useState(false);
  const [filtersValues, setFiltersValues] = useState<any>([]);
  const { company_id = null, is_popup = false } = props;
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { setLoading } = props;

  useEffect(() => {
    setLoading(false);
  }, []);

  const [list, setList] = useState<any[]>([]);
  const [pagination, setPagination] = useState({ total_pages: 1, total_items: 0 });
  const queryParams = new URLSearchParams(window.location.search);
  const currentPage = parseInt(localStorage.getItem('page') || "1");
  const [page, setPage] = useState(currentPage);
  const [refreshList, setRefreshList] = useState(0);
  const [openEditor, setOpenEditor] = useState(false);
  
  const [loadingOrderId, setLoadingOrderId] = useState<number | null>(null); // Estado para manejar el botón en carga

  useEffect(() => {
    setLoading(true);
    loadList();
  }, [page]);

  
  const loadList = async () => {
    setLoading(true);
    await ApiRequest({
      method: 'GET',
      url: '/companies/orders/list',
      query: { page, filters: filtersValues },
      setResponse: (response: any) => {
          setList(response.list);
          setPagination(response.pagination);
        
      }
    })
    setLoading(false);
    
  }

  const downloadInvoice = async (order_id: any) => {
    setLoadingOrderId(order_id); // Activar loadingButton para el order_id específico
    ApiRequest({
      method: 'GET',
      url: '/companies/orders/getInvoice',
      query: { order_id },
      setResponse: (response: any) => {
        window.open(response.fileUrl, '_blank');
        setLoadingOrderId(null); // Desactivar loadingButton después de completar
      },
      onError: () => {
        setLoadingOrderId(null); // Desactivar loadingButton en caso de error
      }
    });
  }

  return (
    <div id='UsersList' className='ModuleContainer'>
      <div className="ModuleWrapper"> 
        <div className="boxContainerHeader">
          <div className="ComponentTitle">
              <h1>Payments</h1>
          </div>
          

          <div className="componentTools">
          </div>
        </div>
        <div id='UsersListIn'>
          <div>
            {list.length !== 0 ? (
              <TableContainer>
                <Table aria-label="sticky table"> 
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ color: '#a8a8a8', borderBottom: "none" }}>Amount</TableCell>
                      <TableCell sx={{ color: '#a8a8a8', borderBottom: "none" }}>Description</TableCell>
                      <TableCell sx={{ color: '#a8a8a8', borderBottom: "none" }}>Customer</TableCell>
                      <TableCell sx={{ color: '#a8a8a8', borderBottom: "none" }}>Invoice link</TableCell>
                      <TableCell sx={{ color: '#a8a8a8', borderBottom: "none" }}>Date</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {list.map((item, i) => (
                      <TableRow key={i} hover role="checkbox">
                        <TableCell>${item.total_price}</TableCell>
                        <TableCell>{item.product_names}</TableCell>
                        <TableCell>{item.email}</TableCell>
                        <TableCell>
                          <LoadingButton
                            variant="contained"
                            color="primary"
                            className='invoice_button'
                            loading={loadingOrderId === item.order_id} // Mostrar loading solo si el order_id coincide
                            onClick={() => downloadInvoice(item.order_id)}
                          >
                            Invoice
                          </LoadingButton>
                        </TableCell>
                        <TableCell>{item.created_at}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <div className="emptyList">
                <EmptyElement title="No orders" />
              </div>
            )}
            {pagination.total_pages > 1 && (
              <ListPagination 
                pagination={pagination} 
                page={page}  
                onChange={(event: any, value: any) => setPage(value)} 
                label="Orders"
                style={{ marginTop: "20px", alignSelf: "center" }}
              />
            )}
          </div>
        </div>
      </div>
      
    </div>
  );
};
