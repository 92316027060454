import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ApiRequest } from 'GlobalFunctions';
import Loader from 'elements/Loader';
import { useSnackbar } from 'notistack';
import { MdOutlineVerifiedUser } from "react-icons/md";


export default function EmailVerification() {
    const [verificationStatus, setVerificationStatus] = useState('verifying');
    const location = useLocation();
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const email = queryParams.get('email');
        const email_confirmation_code = queryParams.get('email_confirmation_code');
        
        if (email && email_confirmation_code) {
            verifyEmail(email, email_confirmation_code);
        } else {
            setVerificationStatus('error');
            enqueueSnackbar('Invalid email verification link.', { variant: 'error' });
        }
    }, [location]);

    const verifyEmail = async (email: string, email_confirmation_code: string) => {
        await ApiRequest({
            url: '/users/email-confirmation',
            method: 'POST',
            body: { 
                email, 
                email_confirmation_code 
            },
            setResponse: async (response: any) => {
                if (response.status == "success") {
                    setVerificationStatus('success');
                    enqueueSnackbar(response.message,{ variant: 'success' });
                } else {
                    setVerificationStatus('error');
                    enqueueSnackbar(response.message, { variant: 'error' });
                }
            }
        });
        
    };

    return (
        <div className="email-verification-container">
            {verificationStatus === 'verifying' && <Loader loading={true} />}
            <div className="email-verification-card">
                <h2>Email Verification</h2>
                
                {verificationStatus === 'success' && (
                    <div className="success-message">
                        <div className="check-icon"><MdOutlineVerifiedUser /></div>
                        <p>Your email has been verified successfully.</p>
                        <a href="/login" className="login-button">Go to Login</a>
                    </div>
                )}
                
                {verificationStatus === 'error' && (
                    <div className="error-message">
                        <div className="error-icon">!</div>
                        <a href="/" className="home-button">Back to Home</a>
                    </div>
                )}
            </div>
        </div>
    );
}