import { Avatar, Button, Divider, ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import { ApiRequest } from 'GlobalFunctions';
import { useEffect, useState, useCallback } from 'react';
import { PiUserCircleLight } from "react-icons/pi";
import { GiExitDoor } from "react-icons/gi";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { set } from 'lodash';
import { color } from '@mui/system';
import { Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import { ProjectConfig } from "Global";
import { useSnackbar } from 'notistack';

export default function TopMenuUser(props: any) {
    const [stores, setStores] = useState([]);
    const [selectedStore, setSelectedStore] = useState<string | null>(null);
    const { setLoading, data } = props;
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const [authPopup, setAuthPopup] = useState<Window | null>(null);

    useEffect(() => {
        GetStores();
        const storedStoreId = localStorage.getItem('store_id');
        if (storedStoreId) {
            setSelectedStore(storedStoreId);
        }
    }, []);

    // Handle messages from the popup window
    const handleAuthMessage = useCallback((event: MessageEvent) => {
        const data = event.data;
        console.log("Received auth data:", data);
        
        if (data && data.status === 'success' && data.ltkn) {
            // Store the auth token
            localStorage.setItem('store_id', data.store_id);
            
            // Notify user
            enqueueSnackbar('Store added successfully! Redirecting...', { 
                variant: 'success',
                autoHideDuration: 3000
            });
            
            // Reload page
            setTimeout(() => {
                window.location.reload();
            }, 2000);
        } else if (data && data.status === 'error') {
            enqueueSnackbar(data.message || 'Authentication failed', { 
                variant: 'error',
                autoHideDuration: 5000
            });
        }
    }, [enqueueSnackbar]);

    // Setup and cleanup the message listener
    useEffect(() => {
        window.addEventListener('message', handleAuthMessage);
        
        return () => {
            window.removeEventListener('message', handleAuthMessage);
            
            // Close the popup if it's still open when component unmounts
            if (authPopup && !authPopup.closed) {
                authPopup.close();
            }
        };
    }, [handleAuthMessage, authPopup]);

    const GetStores = async () => {
        ApiRequest({
            url: '/stores/getStores',
            method: 'GET',
            setResponse: (response: any) => {
                console.log("RESPONSE", response);
                setStores(response);
            }
        });
    };

    const changeStore = (store_id: any) => {
        setLoading(true);
        setSelectedStore(store_id);
        localStorage.setItem('store_id', store_id);
        localStorage.removeItem('checklistOpen');
        window.location.reload();
    };

    const [selectedStoreName, setSelectedStoreName] = useState<string | null>(null);
    
    useEffect(() => {
        if(stores.length === 0) return;

        stores.map((store: any) => {
            console.log("STORE", store);
            console.log("SELECTED STORE", selectedStore);
            if (Number(store.store_id) === Number(selectedStore)) {
                setSelectedStoreName(store.store_name);
            }
        });
    }, [stores, selectedStore]);

    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const logout = async () => {
        localStorage.removeItem('ltkn');
        localStorage.removeItem('store_id');
        localStorage.removeItem('user_id');
        localStorage.removeItem('role_id');
        localStorage.removeItem('checklistOpen');
        window.location.href = '/';
    }

    const handleNewStore = () => {
        setOpen(false);
        openWebflowModal();
    }

    const openWebflowModal = async () => {
        // Get the authentication token
        const ltkn = localStorage.getItem('ltkn');
        if (!ltkn) {
            enqueueSnackbar('Authentication error, please log in again', { 
                variant: 'error',
                autoHideDuration: 3000
            });
            return;
        }
        
        let url = `https://webflow.com/oauth/authorize?response_type=code`
        url += `&client_id=${ProjectConfig.WEBFLOW_CLIENT_ID}`
        url += `&redirect_uri=`
        url += `&state=addsite-${ltkn}` // Send token as part of state
        url += `&scope=assets%3Aread%20assets%3Awrite%20authorized_user%3Aread%20cms%3Aread%20cms%3Awrite%20custom_code%3Aread%20custom_code%3Awrite%20forms%3Aread%20forms%3Awrite%20pages%3Aread%20pages%3Awrite%20sites%3Aread%20sites%3Awrite%20ecommerce%3Aread%20ecommerce%3Awrite%20users%3Aread%20users%3Awrite`;
    
        const popup = window.open(url, '_blank', 'location=yes,scrollbars=yes,status=yes');
        
        setAuthPopup(popup);
    
        if (popup) {
            const popupCheckInterval = setInterval(() => {
                if (popup.closed) {
                    clearInterval(popupCheckInterval);
                    setAuthPopup(null);
                }
            }, 500);
        }
    };

    return (
        <>
            <Button
                id="site-select"
                variant="text"
                className='sites_dropdown'
                onClick={(event) => {
                    setOpen(true);
                    setAnchorEl(event.currentTarget);
                }}
                sx={{ border: "none", textTransform: "none" }}
                endIcon={<KeyboardArrowDownIcon />}
            >
                <Avatar sx={{ backgroundColor: "#428EFF" }} src={data.logo}>
                    <PiUserCircleLight size={30} />
                </Avatar>
                <div className='active_site_name'>{selectedStoreName}</div>
            </Button>

            <Menu 
                anchorEl={anchorEl}
                open={open}
                onClose={() => setOpen(false)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                sx={{ 
                    borderRadius: '20px',
                    marginTop: '40px',
                    padding: '20px',
                }}
                className='sites_dropdown_menu'
            >
                <div className='sites_dropdown_menu_sites'>
                    {stores.map((store: any, index: number) => (
                        <MenuItem 
                            value={store.store_id} 
                            key={index}
                            onClick={() => {
                                setOpen(false);
                                changeStore(store.store_id)
                            }}
                            className='dropdown_item'
                        >
                            <ListItemText>{store.store_name}</ListItemText>
                        </MenuItem>
                    ))}
                    
                    <MenuItem
                        onClick={handleNewStore}
                        className='create_store_btn'
                    >
                        + Create A Store
                    </MenuItem>


                </div>
                <Divider style={{ borderColor: '#ECEBFF' }} />

                <MenuItem 
                    onClick={logout}
                    className='logout'
                >
                    <ListItemText>Logout</ListItemText>
                </MenuItem>
            </Menu>
        </>
    );
}
