import React, { useState, useEffect, useRef } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
  Switch,
  FormControlLabel,
  Select,
  MenuItem,
  IconButton,
  FormControl,
  InputLabel,
  Divider,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import CloseIcon from "@mui/icons-material/Close";
import { useSnackbar } from "notistack";
import { ApiRequest } from "GlobalFunctions";
import AutocompleteField from "elements/AutocompleteField";
import { CiLock } from "react-icons/ci";
import { PiEyeSlashLight } from "react-icons/pi";
import { PiEyeLight } from "react-icons/pi";
import { GoChevronLeft } from "react-icons/go";
import { PiUser } from "react-icons/pi";
import img_placeholder from "assets/img/img_placeholder.png";
import welcome_img1 from "assets/img/pc2-welcome-img1.svg";
import welcome_img2 from "assets/img/welcome_img2.png";
import { PiUploadSimpleLight } from "react-icons/pi";


import ISwitch from "elements/ISwitch";
import ImageUploader from "elements/ImageUploader";


export default function WelcomePopup() {
  const { enqueueSnackbar } = useSnackbar();
  const [showPassword, setShowPassword] = useState(false);
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const defaultValues = {
    logo: "",
    company_name: "",
    contact_person_name: "",
    contact_person_lastname: "",
    email: "",
    password: "",
    email_subscribe: true,
    country_id: "1",
    region_id: "",
    city_id: "",
    website_url: "",
    annual_revenue: "", // Changed from annual_revenue_id to annual_revenue
    is_new: false
  };

  // Revenue options mapping for display purposes
  const revenueOptions = [
    { value: "$0 – $10K (Just starting)", label: "$0 – $10K (Just starting)" },
    { value: "$10K – $50K (Side business or small store)", label: "$10K – $50K (Side business or small store)" },
    { value: "$50K – $250K (Growing eCommerce brand)", label: "$50K – $250K (Growing eCommerce brand)" },
    { value: "$250K – $1M (Established business)", label: "$250K – $1M (Established business)" },
    { value: "$1M – $5M (Scaling fast)", label: "$1M – $5M (Scaling fast)" },
    { value: "$5M+ (Enterprise level)", label: "$5M+ (Enterprise level)" }
  ];

  const [accountValues, setAccountValues] = useState(defaultValues);
  const [step, setStep] = useState(1);
  const [countries, setCountries] = useState([]);

  useEffect(() => {
    getAccountDetails();
  }, []); // Run on component mount

  const handleInputChange = (e: any) => {
    const { name, value, type, checked } = e.target;
    setAccountValues({
      ...accountValues,
      [name]: type === "checkbox" ? checked : value,
    });
    
    // Clear error when user types
    if (errors[name]) {
      setErrors({
        ...errors,
        [name]: ''
      });
    }
  };

  const handleClose = () => setOpen(false);
  
  const validateStep1 = () => {
    const newErrors: Record<string, string> = {};
    let isValid = true;
    
    if (!accountValues.contact_person_name) {
      newErrors.contact_person_name = "First Name is required";
      isValid = false;
    }
    
    if (!accountValues.contact_person_lastname) {
      newErrors.contact_person_lastname = "Last Name is required";
      isValid = false;
    }
    
    if (!accountValues.email) {
      newErrors.email = "Email is required";
      isValid = false;
    } else if (!validateEmail(accountValues.email)) {
      newErrors.email = "Please enter a valid email address";
      isValid = false;
    }
    
    if (!accountValues.password) {
      newErrors.password = "Password is required";
      isValid = false;
    }
    
    setErrors(newErrors);
    return isValid;
  };
  
  const nextStep = () => {
    if (validateStep1()) {
      setStep(2);
    }
  };
  
  const prevStep = () => setStep(1);

  const validateEmail = (email: string) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  /*=============================================
  GET COUNTRIES
  =============================================*/
  const getCountries = async () => {
    await ApiRequest({
      url: '/countries/getCountries',
      method: 'GET',
      setResponse: (response: any) => {
        setCountries(response);
      }
    });
  };

   /*=============================================
    GET ACCOUNT DETAILS
    =============================================*/
    const getAccountDetails = async () => {
        await getCountries();
        await ApiRequest({
            method: 'GET',
            url: '/stores/getStoreAccount',
            setResponse: (response: any) => {
                setAccountValues(response.data);
                setOpen(response.data.is_new === true);
            }
        });
    }
  
  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const [files, setFiles] = useState<any>([]);
  const imageUploaderRef = useRef<any>(null);

  const triggerFileInput = () => {
    // Find the input element inside the ImageUploader component
    const inputElement = document.querySelector('.welcome_popup_wrapper input[type="file"]');
    if (inputElement) {
      (inputElement as HTMLInputElement).click();
    }
  };

  const handleRemoveLogo = () => {
    // Clear the logo from accountValues
    setAccountValues({
      ...accountValues,
      logo: ""
    });
    // Clear the file
    setFiles(null);
    
    // Find and clear the file input to allow re-uploading the same file
    const inputElement = document.querySelector('.welcome_popup_wrapper input[type="file"]');
    if (inputElement) {
      (inputElement as HTMLInputElement).value = '';
    }
  };

  const updateAccount = () => {
    console.log(accountValues);
    // Validate all required fields for step 2
    const requiredFields: Record<string, string> = {
      company_name: 'Company Name',
      country_id: 'Billing Country',
      region_id: 'State/Region',
      city_id: 'City',
      website_url: 'Website URL',
      annual_revenue: 'Annual Revenue', // Changed from annual_revenue_id to annual_revenue
    };
    
    const newErrors: Record<string, string> = {};
    let isValid = true;
    
    for (const [field, label] of Object.entries(requiredFields)) {
      if (!(accountValues as any)[field]) {
        newErrors[field] = `${label} is required`;
        isValid = false;
      }
    }
    
    setErrors(newErrors);
    
    if (!isValid) {
      return;
    }

    // Set loading to true before API request
    setLoading(true);

    const body = new FormData();
    body.append('formValues', JSON.stringify(accountValues));

    if (files) {
        body.append("logo", files);
    }

    ApiRequest({
        method: 'POST',
        url: '/stores/updateStoreAccount',
        formData: true,
        body: body,
        setResponse: (response: any) => {
            enqueueSnackbar('Account updated', { variant: 'success' });
            // Reload the page after successful submission
            window.location.reload();
        },
        onError: (error: any) => {
            enqueueSnackbar(error.message || 'Failed to update account', { variant: 'error' });
        },
        onFinally: () => {
            setLoading(false); // Set loading to false regardless of success or error
        }
    });
  }

  const truncateText = (text: string, maxLength: number) => {
    if (!text) return "";
    return text.length > maxLength ? `${text.substring(0, maxLength)}...` : text;
  };

  return (
    <Dialog 
      open={open} 
      fullWidth 
      maxWidth="md" 
      PaperProps={{
        style: { 
          maxWidth: '1035px',
          borderRadius: '16px',
        }
      }}
      sx={{
        backdropFilter: "blur(4px)"
      }}
      slotProps={{
        backdrop: {
          sx: {
            backgroundColor: '#595563A6',
          }
        }
      }}
    >
        


        <div className="welcome_popup_wrapper">
          {/* Formulario */}
          <div className="welcome_popup_form">

            {step === 1 ? (
               
              <div className="form">
                 <h2 className="form_title">Welcome To Penni Cart!</h2>


                 
                 <div className="account_logo_wrapper">
                    <ImageUploader 
                        className="account_logo"
                        image={accountValues.logo} 
                        setImage={(newImage) => setAccountValues({ ...accountValues, logo: newImage })}
                        setFiles={setFiles} 
                        acceptedFormats={["png", "jpg", "jpeg"]}
                        maxSizeInMB={10}
                        onClear={() => console.log("Image cleared")}
                    />

                    {/* Conditionally render Upload or Remove button based on logo existence */}
                    <div className="account_logo_buttons">
                      <span className="external_label">Profile Picture</span>
                      <div className="welcome_upload_button_wrapper">
                        
                          <Button 
                            variant="outlined"
                            onClick={triggerFileInput}
                            startIcon={<PiUploadSimpleLight size={20} color="#B9B9B9" />}
                            className="welcome_upload_button"
                            size="small"
                            sx={{
                              '& .MuiButton-startIcon': {
                                marginRight: "14px"
                              }
                            }}
                          >
                            {!accountValues.logo ? "Upload Image" : "Replace Image"}
                          </Button>
                      
                          <Button 
                            variant="outlined"
                            onClick={handleRemoveLogo}
                            className="welcome_remove_button"
                            size="small"
                          >
                            Remove
                          </Button>
                       
                      </div>
                      <div className='welcome_popup_tip'>*.png, *.jpg files up to 10MB at least 400px by 400px</div>
                    </div>
                  </div>

                <div className="input_row">
                    <div className="textfield_wrapper">
                    <InputLabel className="external_label">First Name</InputLabel>
                    <TextField
                        fullWidth
                        name="contact_person_name"
                        value={accountValues.contact_person_name}
                        onChange={handleInputChange}
                        error={!!errors.contact_person_name}
                        helperText={errors.contact_person_name}
                    />
                    </div>

                    <div className="textfield_wrapper">
                    <InputLabel className="external_label">Last Name</InputLabel>
                    <TextField
                        fullWidth
                        name="contact_person_lastname"
                        value={accountValues.contact_person_lastname}
                        onChange={handleInputChange}
                        error={!!errors.contact_person_lastname}
                        helperText={errors.contact_person_lastname}
                    />
                    </div>
                </div>
                
                <div className="textfield_wrapper">
                  <InputLabel className="external_label">Email</InputLabel>
                  <TextField
                    fullWidth
                    name="email"
                    type="email"
                    value={accountValues.email}
                    onChange={handleInputChange}
                    error={!!errors.email}
                    helperText={errors.email}
                  />
                </div>
                <div className="textfield_wrapper">
                  <InputLabel className="external_label">Password</InputLabel>
                  <TextField
                    fullWidth
                    name="password"
                    type={showPassword ? "text" : "password"}
                    value={accountValues.password}
                    onChange={handleInputChange}
                    error={!!errors.password}
                    helperText={errors.password}
                    sx={{
                      '& .MuiInputBase-input': {
                        padding: '12px 0 !important',
                      },
                    }}
                    InputProps={{
                        startAdornment: <CiLock size={24} color="#B9B9B9" style={{marginRight: 10}} />,
                        endAdornment: (
                          <IconButton 
                            onClick={handleTogglePasswordVisibility}
                            edge="end"
                            className="show_password_button" 
                          >
                            {showPassword ? 
                              <PiEyeLight size={24} color="#B9B9B9"/> : 
                              <PiEyeSlashLight size={24} color="#B9B9B9"/>
                            }
                          </IconButton>
                        )
                    }}
                  />
                </div>
                {/* <FormControlLabel
                  control={
                    <Switch
                      checked={accountValues.subscribe}
                      onChange={handleInputChange}
                      name="subscribe"
                    />
                  }
                  label="Subscribe To Product Update Emails"
                /> */}
                <Divider sx={{ borderColor: '#ECEBFF', margin: '10px 0' }} />
                <div className='subscribe_wrapper'>
                    <div className='subscribe_label_wrapper'>
                        <div className='external_label'>Subscribe To Product Update Emails</div>
                        <div className='subscribe_subtitle'>Get the latest updates about features and product updates</div>
                    </div>
                    <ISwitch
                        name="email_subscribe"
                        checked={accountValues.email_subscribe}
                        onChange={handleInputChange}
                    />
                </div>
                


                <Button variant="contained" fullWidth onClick={nextStep} className="welcome_button">
                  Continue
                </Button>
              </div>
            ) : (
              <div className="form">
                <h2 className="form_title">
                  <IconButton onClick={prevStep} sx={{ mr: 1, p: 0 }}>
                    <GoChevronLeft size={24} />
                  </IconButton>
                  Let's Get To Know You
                </h2>
                <div className="textfield_wrapper">
                  <InputLabel className="external_label">Company Name</InputLabel>
                  <TextField
                    fullWidth
                    name="company_name"
                    value={accountValues.company_name}
                    onChange={handleInputChange}
                    error={!!errors.company_name}
                    helperText={errors.company_name}
                  />
                </div>

                
                <div className="textfield_wrapper">
                  <InputLabel className="external_label">Billing Country</InputLabel>
                  <TextField
                    id="country_select"
                    value={accountValues.country_id}
                    name="country_id"
                    onChange={(e) => {
                      setAccountValues({
                        ...accountValues,
                        country_id: e.target.value,
                        region_id: '',
                        city_id: ''
                      });
                      
                      if (errors.country_id) {
                        setErrors({
                          ...errors,
                          country_id: ''
                        });
                      }
                    }}
                    select
                    fullWidth
                    error={!!errors.country_id}
                    helperText={errors.country_id}
                  >
                    {countries.map((country: any, index: number) => (
                      <MenuItem key={index} value={country.country_id}>{country.country_name}</MenuItem>
                    ))}
                  </TextField>
                </div>
                  
                <div className="input_row">
                  
                    <div className="textfield_wrapper">
                      <InputLabel className="external_label">State/Region</InputLabel>
                      <AutocompleteField
                        endpoint="/countries/getRegions"
                        endpoint_params={{ country_id: accountValues.country_id }}
                        value={accountValues.region_id}
                        className="autocomplete_small"
                        onChange={(data: any) => {
                          setAccountValues({
                            ...accountValues,
                            region_id: data ? data.value : '',
                            city_id: ''
                          });
                          
                          if (errors.region_id) {
                            setErrors({
                              ...errors,
                              region_id: ''
                            });
                          }
                        }}
                        fullWidth
                        error={!!errors.region_id}
                        helperText={errors.region_id}
                        disabled ={(accountValues.country_id == '' || accountValues.country_id == '0')}
                      />
                    </div>

                  {accountValues.region_id !== '' && accountValues.region_id != '0' && (
                    <div className="textfield_wrapper">
                      <InputLabel className="external_label">City</InputLabel>
                      <AutocompleteField
                        endpoint="/shipping/zones/getCities"
                        endpoint_params={{ region_id: accountValues.region_id }}
                        max_results={10}
                        dynamic={true}
                        value={accountValues.city_id}
                        className="autocomplete_small"
                        onChange={(value: any) => {
                          setAccountValues({
                            ...accountValues,
                            city_id: value ? value.value : ''
                          });
                          
                          if (errors.city_id) {
                            setErrors({
                              ...errors,
                              city_id: ''
                            });
                          }
                        }}
                        clearOnSelect={true}
                        fullWidth
                        error={!!errors.city_id}
                        helperText={errors.city_id}
                      />
                    </div>
                  )}
                </div>

                <div className="textfield_wrapper">
                  <InputLabel className="external_label">Website URL</InputLabel>
                  <TextField
                    fullWidth
                    name="website_url"
                    autoComplete="off"
                    value={accountValues.website_url || ""}
                    onChange={handleInputChange}
                    error={!!errors.website_url}
                    helperText={errors.website_url}
                  />
                </div>

                <div className="textfield_wrapper">
                  <InputLabel className="external_label">Annual Revenue</InputLabel>
                  <TextField
                    fullWidth
                    name="annual_revenue"
                    value={accountValues.annual_revenue}
                    onChange={handleInputChange}
                    select
                    error={!!errors.annual_revenue}
                    helperText={errors.annual_revenue}
                  >
                    <MenuItem value="">Select an option</MenuItem>
                    {revenueOptions.map((option, index) => (
                      <MenuItem key={index} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>

                <LoadingButton 
                  variant="contained" 
                  fullWidth 
                  onClick={updateAccount} 
                  className="welcome_button"
                  loading={loading}
                >
                  Submit
                </LoadingButton>
              </div>
            )}
          </div>

          {/* Imagen (Placeholder) */}
          <div className="welcome_popup_image_wrapper" id={step === 1 ? "welcome_step1" : "welcome_step2"}>

              


            
            <img className={"welcome_popup_image" + (step == 2 ? " step_2" : "")}
            src={step === 1 ? welcome_img1 : welcome_img1} alt="Welcome" />
            {step === 2 && ( 
              <>
              <div className="step_2_gradient"></div>
                <div className="welcome_signup_preview"> 
                  <div className="welcome_signup_header">
                    <img src={accountValues.logo || img_placeholder} alt="Logo" />
                    <h2>{truncateText(accountValues.company_name, 25) || "Company Name"}</h2>
                  </div>

                  <div className="welcome_signup_body">
                    <div className="welcome_signup_item">
                      <PiUser size={24} />
                      <span>{truncateText(accountValues.email, 25) || "Email"}</span>
                    </div>
                    <Button variant="contained" fullWidth className="welcome_button">
                    Create A New Store
                  </Button>
                  </div>
                  
                </div>
              </>
            )}
            


          </div>
        </div>
    </Dialog>
  );
}


