import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import './assets/css/main.css';
import MainMenu from './components/Menus/Menu';
import logo from './assets/img/logo.svg';
import { ThemeProvider } from '@mui/material/styles';
import { SnackbarProvider } from 'notistack';
import AppRoutes from './AppRoutes';
import { BrowserRouter as Router, useLocation, useSearchParams } from 'react-router-dom';
import Login from './components/Login/Login';
import { theme } from './Theme';
import Loader from 'elements/Loader';
import { CiDesktop } from "react-icons/ci";
import TopMenuUser from 'components/Menus/TopMenuUser';
import LinkWebflow from 'components/Companies/Webflow/Link';
import { ApiRequest } from 'GlobalFunctions';
import TopMenuAdmin from 'components/Menus/TopMenuAdmin';
import Oauth from 'components/Oauth/Oauth';
import OauthAdmin from 'components/Admin/Oauth/Oauth';

import LoginV2 from 'components/LoginV2/Login';
import Checklist from 'components/Companies/OnboardingChecklist/Checklist';
import WelcomePopup from 'components/Companies/WelcomePopup/WelcomePopup';
import EmailConfirmation from 'components/LoginV2/EmailConfirmation';
import FloatingVideo from 'elements/FloatingVideo/FloatingVideo';
import { IntercomProvider, useIntercom } from 'react-use-intercom';



const Site = () => {
  const userIsLogged = localStorage.getItem('ltkn') !== null;
  
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const isHome = location.pathname === "/" ? "home" : "";
  const [isMobile, setIsMobile] = useState(window.innerWidth < 700);

  // Check if current path is email verification
  const isEmailVerificationPath = location.pathname === '/email-confirmation';

  useEffect(() => {
    // Check for the webflow open parameter
    const openWebflow = searchParams.get('openWebflow');
    const webflowUrl = searchParams.get('webflowUrl');
    
    if (openWebflow === 'true' && webflowUrl) {
      // Open Webflow in a new tab
      window.open(decodeURIComponent(webflowUrl), '_blank');
      
      // Clear the parameters to avoid reopening the tab on refreshes
      searchParams.delete('openWebflow');
      searchParams.delete('webflowUrl');
      setSearchParams(searchParams);
    }
    
    const handleResize = () => {
      setIsMobile(window.innerWidth < 700);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [searchParams, setSearchParams]);

  const storeAlreadyLinked = localStorage.getItem('store_id') !== null;
  const role_id = localStorage.getItem('role_id') || "0";

  const [companyData, setCompanyData] = useState<any>({
    logo: '',
  });
  
  const [userData, setUserData] = useState<any>({
    image: '',
    name: '',
    lastname: '',
  });

  useEffect(() => {
    GetUserInfo();
  }, []);

  const GetUserInfo = async () => {
    if(!userIsLogged) return;
    const userInfo = await ApiRequest({
        url: '/users/getUserData',
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
        setResponse: (response: any) => {
          if(response.role_id === 1) {
            setCompanyData(response.data);
            if(!storeAlreadyLinked && response.data.default_store_id) {
              localStorage.setItem('store_id', response.data.default_store_id);
            }
          }
          else {
            setUserData(response.data);
          }

          
            
        }
    });
  }

  const [open, setOpen] = useState(true);


  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider maxSnack={3}>
        {isMobile ? (
          <div className='noMobile'>
            <div className='noMobileContent'>
              <CiDesktop size={80} />
              <h2>Please use a desktop device to access this site</h2>
              <span>Pennicart needs a bigger screen to work properly</span>
            </div>
          </div>
        ) : (
          // Allow email verification route without login
          isEmailVerificationPath ? (
            <EmailConfirmation />
          ) : !userIsLogged ? (
            <LoginV2 setLoading={setLoading} />
          ) : (
            (Number(role_id) < 100 && location.pathname === '/webflow') ? (
              <LinkWebflow setLoading={setLoading} new_store={!storeAlreadyLinked} />
            ) : Number(role_id) < 100 && location.pathname === '/oauth' ? (
              <Oauth setLoading={setLoading} />
            ) : Number(role_id) === 100 && location.pathname === '/admin/oauth' ? (
              <OauthAdmin setLoading={setLoading} />
            ) : (
              <div id='structure'>
                <div id="main-container">
                  <div id='header_container'>
                    <header data-pc="nav-component" className="nav_padding">
                      <div className="nav_container">
                        <div className="nav_logo w-embed">
                          <a href='/'><img src={logo} alt="Logo"></img></a>
                        </div>
                        {Number(role_id) < 100 ?
                          <TopMenuUser setLoading={setLoading} data={companyData}/>
                          :
                          <TopMenuAdmin setLoading={setLoading} data={userData}/>
                        }

                      </div>
                    </header>
                    <aside id="menu">
                      <MainMenu setLoading={setLoading} />
                    </aside>
                  </div>
                  <div id="main-content">
                    <Loader loading={loading} />
                    <div id='content'>
                      <AppRoutes setLoading={setLoading} GetUserInfo={GetUserInfo}/>
                      {Number(role_id) < 100 && (
                        <>
                          <Checklist /> 
                          <WelcomePopup />
                          <FloatingVideo />
                          <IntercomProvider
                            appId="bctzes5k"
                            autoBoot
                          />
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )
          )
        )}
      </SnackbarProvider>
    </ThemeProvider>
  );
};

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(

    <Router>
      <Site />
    </Router>
);
