import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyDUKW_2dGBULgQufPhnvCmd8f7PwztvvFo",
  authDomain: "penni-cart.firebaseapp.com",
  projectId: "penni-cart",
  storageBucket: "penni-cart.firebasestorage.app",
  messagingSenderId: "1022955290253",
  appId: "1:1022955290253:web:91f51a58005b8341ce2cc4"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const googleProvider = new GoogleAuthProvider();
