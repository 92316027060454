import { useEffect, useState } from 'react';
import { Portal, Dialog, IconButton } from '@mui/material';
import { IoIosAlert } from "react-icons/io";
import { MdClose } from "react-icons/md";
import { useLocation } from 'react-router-dom';
import videoLinks from './videoLinks.json';
import { FaPlay } from "react-icons/fa6";

export default function FloatingVideo() {
  const [moduleTitleEl, setModuleTitleEl] = useState<Element | null>(null);
  const [openLightbox, setOpenLightbox] = useState(false);
  const location = useLocation();
  
  // Determine the current page from the URL path
  const getCurrentPage = (): string => {
    const path = location.pathname.toLowerCase();
    
    // Map URL paths to keys in our videoLinks JSON
    if (path.includes('orders')) return 'orders';
    if (path.includes('account')) return 'account';
    if (path.includes('coupons')) return 'coupons';
    if (path.includes('shippingzones')) return 'shippingZones';
    if (path.includes('taxes')) return 'taxes';
    if (path.includes('invoice')) return 'invoice';
    if (path.includes('logic')) return 'logic';
    if (path.includes('pay')) return 'payment';
    
    // Default to orders if no match found
    return 'orders';
  };
  
  const currentPage = getCurrentPage();
  const videoData = videoLinks[currentPage as keyof typeof videoLinks] || videoLinks.orders;

  useEffect(() => {
    // Función para buscar el elemento .ComponentTitle
    const findModuleTitle = () => {
      const targetEl = document.querySelector('.ComponentTitle');
      if (targetEl) {
        setModuleTitleEl(targetEl);
        return true;
      }
      return false;
    };

    // Verificar inmediatamente y establecer un intervalo para verificaciones periódicas
    findModuleTitle();
    
    // Configurar un observador para detectar cambios en el DOM
    const observer = new MutationObserver(() => {
      findModuleTitle();
    });
    
    observer.observe(document.body, { childList: true, subtree: true });
    
    // También verificar periódicamente (navegación SPA puede no disparar MutationObserver)
    const intervalId = setInterval(findModuleTitle, 500);

    // Limpiar recursos
    return () => {
      observer.disconnect();
      clearInterval(intervalId);
    };
  }, []);

  const handleOpenLightbox = () => {
    setOpenLightbox(true);
  };

  const handleCloseLightbox = () => {
    setOpenLightbox(false);
  };

  // Function to append autoplay parameter to video URL
  const getAutoplayUrl = (url: string): string => {
    // Check if the URL already has parameters
    const hasParams = url.includes('?');
    // Add autoplay parameter accordingly
    return `${url}${hasParams ? '&' : '?'}autoplay=1`;
  };

  // Function to extract YouTube video ID from URL
  const getYouTubeVideoId = (url: string): string | null => {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);
    return (match && match[2].length === 11) ? match[2] : null;
  };

  // Function to get YouTube thumbnail URL from video URL
  const getYouTubeThumbnailUrl = (videoUrl: string): string => {
    const videoId = getYouTubeVideoId(videoUrl);
    return videoId ? `https://img.youtube.com/vi/${videoId}/hqdefault.jpg` : '';
  };

  // El contenido del componente
  const content = (
    <div className='FloatingVideo' onClick={handleOpenLightbox} style={{ cursor: 'pointer' }}>
      <div className='FloatingVideoLink'>
        <IoIosAlert color='#7963FF' />
        {videoData.title}
      </div>
      <div style={{ position: 'relative', width: '250px', height: '130px' }} className='FloatingVideoThumbnail'>
        <img 
          src={getYouTubeThumbnailUrl(videoData.videoUrl)} 
          alt='Floating Video'
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover'
          }}
        />
        <div 
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            borderRadius: '50%',
            width: '50px',
            height: '50px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <FaPlay color='white' size={26} />
        </div>
      </div>
    </div>
  );

  // Si estamos en el servidor o no encontramos el elemento objetivo, no renderizamos nada
  if (typeof window === 'undefined' || !moduleTitleEl) return null;

  // Usar MUI Portal para renderizar dentro del div .ComponentTitle
  return (
    <>
      <Portal container={moduleTitleEl}>
        {content}
      </Portal>

      <Dialog 
        open={openLightbox} 
        onClose={handleCloseLightbox} 
        maxWidth="md" 
        fullWidth
      >
        <div style={{ position: 'relative' }}>
          <IconButton 
            onClick={handleCloseLightbox} 
            style={{ 
              position: 'absolute', 
              right: 8, 
              top: 8, 
              zIndex: 1, 
              backgroundColor: 'rgba(0,0,0,0.3)', 
              color: 'white' 
            }}
          >
            <MdClose />
          </IconButton>
          <div style={{ padding: '56.25% 0 0 0', position: 'relative' }}>
            <iframe 
              width="560"
              height="315"
              src={openLightbox ? getAutoplayUrl(videoData.videoUrl) : videoData.videoUrl}
              title={videoData.title}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin"
              allowFullScreen
              style={{ 
                position: 'absolute', 
                top: 0, 
                left: 0, 
                width: '100%', 
                height: '100%'
              }}
            ></iframe>
          </div>
        </div>
      </Dialog>
    </>
  );
}


