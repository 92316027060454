import Signup from "./Signup";
import Signin from "./Signin";
import { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import logo from '../../assets/img/logo.svg';
import WebflowOauth from "./components/WebflowReceiver";
import auth_img1 from '../../assets/img/pc2-auth-img1.avif';
import auth_img2 from '../../assets/img/pc2-auth-img2.png';
import auth_img3 from '../../assets/img/pc2-auth-img3.png';
import auth_cursor from '../../assets/img/auth_cursor.png';

export default function Login(props: any) {

    const [loading, setLoading] = useState(false);

    const [activeView, setActiveView] = useState<'signin' | 'signup'>('signin');
    const [showWebflowOauth, setShowWebflowOauth] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();
    const authImg1Ref = useRef<HTMLImageElement>(null);
    const [authImg1Width, setAuthImg1Width] = useState(0);
    const [parallaxOffset, setParallaxOffset] = useState({ x: 0, y: 0 });

    const updateAuthImg1Width = () => {
        if (authImg1Ref.current) {
            setAuthImg1Width(authImg1Ref.current.offsetWidth);
        }
    };

    const handleMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
        const { clientX, clientY, currentTarget } = e;
        const { left, top, width, height } = currentTarget.getBoundingClientRect();
        const x = ((clientX - left) / width - 0.5) * 20; // Scale factor for parallax
        const y = ((clientY - top) / height - 0.5) * 20; // Scale factor for parallax
        setParallaxOffset({ x, y });
    };

    useEffect(() => {
        // Update width on mount and on window resize
        updateAuthImg1Width();
        window.addEventListener("resize", updateAuthImg1Width);
        return () => {
            window.removeEventListener("resize", updateAuthImg1Width);
        };
    }, []);

    useEffect(() => {
        if (location.pathname.includes('WebflowOauth')) {
            setShowWebflowOauth(true);
        } else if (location.pathname.includes('signup')) {
            setActiveView('signup');
        } else {
            setActiveView('signin');
            // If path doesn't explicitly include /login, add it
            if (!location.pathname.includes('/login')) {
                navigate('/login', { replace: true });
            }
        }
    }, [location, navigate]);

    if (showWebflowOauth) {
        return <WebflowOauth />;
    }

    return (
        <div id="login_main_container">
            <div className="login_form_side">
                <img src={logo} alt="" className="login_logo" />
                <div className="login_form_container">
                    <div className="login-toggle-buttons">
                        <button 
                            className={`toggle-btn ${activeView === 'signin' ? 'active' : ''}`}
                            onClick={() => {
                                navigate('/login');
                            }}
                        >
                            Sign In
                        </button>
                        <button 
                            className={`toggle-btn ${activeView === 'signup' ? 'active' : ''}`}
                            onClick={() => {
                                navigate('/signup');
                            }}
                        >
                            Sign Up
                        </button>
                    </div>
                    {activeView === 'signin' ? <Signin setLoading={setLoading} /> : <Signup setLoading={setLoading} />}
                </div>


            </div>
            <div 
                className="login_image_side" 
                onMouseMove={handleMouseMove}
                onMouseLeave={() => {
                    // Do nothing to maintain the last parallax offset
                }}
            >
                <div className="auth_img_wrapper">
                    <img 
                        src={auth_img1} 
                        alt="" 
                        className="auth_img1" 
                        id="auth_img1" 
                        ref={authImg1Ref} 
                        style={{
                        }}
                    />
                    <img 
                        src={auth_img2} 
                        alt="" 
                        className="auth_img2" 
                        style={{
                            bottom: `${authImg1Width * (1 - 1.3)}px`, 
                            right: `${authImg1Width * (1 - 0.6)}px`,
                            transform: `translate(${parallaxOffset.x * -4.5}px, ${parallaxOffset.y * 2.5}px)` 
                        }} 
                    />
                    <img 
                        src={auth_img3} 
                        alt="" 
                        className="auth_img3" 
                        style={{
                            top: `${authImg1Width * (1 - 1.35)}px`, 
                            right: `${authImg1Width * (1 - 1.1)}px`,
                            transform: `translate(${parallaxOffset.x * 2.4}px, ${parallaxOffset.y * -4.4}px)`
                        }} 
                    />
                    <img 
                        src={auth_cursor} 
                        alt="" 
                        className="auth_cursor" 
                        style={{
                            transform: `translate(${parallaxOffset.x * 3.4}px, ${parallaxOffset.y * 3.4}px)`
                        }}
                    />
                </div>
            </div>
        </div>
    )
}