export const ProjectConfigOverride = {
  api_url: "https://pcapi.osabana.com",
  WEBFLOW_CLIENT_ID: "04e6713602ea2529e63bf5ac314d691dbe5c3e76fc90ec43f909bbd2e4996797",

  /*============================================
  SQUARE
  =============================================*/
  SQUARE_CLIENT_ID: "sandbox-sq0idb-UaKhFLJvaHZdzjOxFc9e3g",
  SQUARE_BASE_URL: "https://connect.squareupsandbox.com",
  SQUARE_REDIRECT_URI: "https://pcapi.osabana.com/square/auth",


  };