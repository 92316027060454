import { Button } from "@mui/material";
import { ProjectConfig } from "Global";
import { ApiRequest } from "GlobalFunctions";
import { useEffect, useState, useCallback } from "react";
import { useSnackbar, withSnackbar } from 'notistack';

export default function Signup(props:any) {

    const { setLoading } = props;
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [sites, setSites] = useState([]);
    const [authPopup, setAuthPopup] = useState<Window | null>(null);

    // Handle messages from the popup window
    const handleAuthMessage = useCallback((event: MessageEvent) => {
        // You might want to add origin validation in production
        // if (event.origin !== "https://api.test.com") return;
        
        const data = event.data;
        console.log("Received auth data:", data);
        
        if (data && data.status === 'success' && data.ltkn) {
            // Store the auth token
            localStorage.setItem('ltkn', data.ltkn);
            localStorage.setItem('role_id', "1")
            localStorage.setItem('store_id', data.store_id);
            
            // Notify user
            enqueueSnackbar('Account created successfully! Redirecting...', { 
                variant: 'success',
                autoHideDuration: 3000
            });
            
            setTimeout(() => {
                if (data.shortName) {
                    // REDIRECT APPROACH:
                    // Redirect to main page with webflow parameters instead of opening a new tab directly
                    const webflowUrl = `https://${data.shortName}.design.webflow.com/?app=${ProjectConfig.WEBFLOW_CLIENT_ID}&mode=.`;
                    window.location.href = `/account/?openWebflow=true&webflowUrl=${encodeURIComponent(webflowUrl)}`;
                } else {
                    // If no shortName, just refresh the page
                    window.location.reload();
                }
            }, 2000);
        } else if (data && data.status === 'error') {
            enqueueSnackbar(data.message || 'Authentication failed', { 
                variant: 'error',
                autoHideDuration: 5000
            });
        }
    }, [enqueueSnackbar]);

    // Setup and cleanup the message listener
    useEffect(() => {
        window.addEventListener('message', handleAuthMessage);
        
        return () => {
            window.removeEventListener('message', handleAuthMessage);
            
            // Close the popup if it's still open when component unmounts
            if (authPopup && !authPopup.closed) {
                authPopup.close();
            }
        };
    }, [handleAuthMessage, authPopup]);

    const openWebflowModal = async () => {
        const ltkn = localStorage.getItem('ltkn');
    
        let url = `https://webflow.com/oauth/authorize?response_type=code`
        url += `&client_id=${ProjectConfig.WEBFLOW_CLIENT_ID}`
        url += `&redirect_uri=`
        url += `&state=signup`
        url += `&scope=assets%3Aread%20assets%3Awrite%20authorized_user%3Aread%20cms%3Aread%20cms%3Awrite%20custom_code%3Aread%20custom_code%3Awrite%20forms%3Aread%20forms%3Awrite%20pages%3Aread%20pages%3Awrite%20sites%3Aread%20sites%3Awrite%20ecommerce%3Aread%20ecommerce%3Awrite%20users%3Aread%20users%3Awrite`;
    
        const popup = window.open(url, '_blank', 'location=yes,scrollbars=yes,status=yes');
        
        setAuthPopup(popup);
    
        if (popup) {
            const popupCheckInterval = setInterval(() => {
                if (popup.closed) {
                    clearInterval(popupCheckInterval);
                    setAuthPopup(null);
                }
            }, 500);
        }
    };

    return (
        <div className="signup_container">
            <h2>Quick Connect</h2>
            <p>Create Your Penni Cart Account</p>

                <Button 
                    variant="contained" 
                    color="primary"
                    onClick={openWebflowModal}
                >
                    Create Account
                </Button>
        </div>
    )
}