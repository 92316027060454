import { Button, Dialog, FormControl, InputLabel, MenuItem, Select, styled, Switch, SwitchProps, Table, TableBody, TableCell, TableRow, TextField, LinearProgress as MuiLinearProgress, Tooltip } from "@mui/material"
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Box from "elements/Box";
import {ProjectConfig} from 'Global';
import { useSnackbar, withSnackbar } from "notistack";
// Remove this import since we're not using Joy UI's LinearProgress anymore
// import LinearProgress from '@mui/joy/LinearProgress';
import ISwitch from "elements/ISwitch";
import { count } from "console";
import AlertDialog from "elements/AlertDialog";
import RefundsList from "./Refunds/RefundList";
import AddCreditCard from "./AddCreditCard";
import PlanChanger from "./PlanChanger";


//TODAY DATE FORMATED MM/DD/YYYY
const today = new Date('2014-08-18T21:11:54')

// Add this styled component near the top of the file, after imports
const BorderLinearProgress = styled(MuiLinearProgress)(({ theme }) => ({
  height: 24,
  borderRadius: 8,
  [`&.MuiLinearProgress-root`]: {
    backgroundColor: theme.palette.grey[200],
  },
  [`& .MuiLinearProgress-bar`]: {
    borderRadius: 8,
  },
}));

export default function StoreEditor(props : any) {

  const {
    setLoading,
  } = props;

  //get store id param
  const { store_id } = useParams();


  /*SNACKBAR FUNCTION FOR NOTIFICATIONS*/
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  /*============================================= */

  const [openDeleteAlert, setOpenDeleteAlert] = useState(false);


  const navigate = useNavigate();
  const defaultValues: any = {
    store_id: store_id,
    company_name: "",
    name: "",
    lastname: "",
    phone: "",  
    industry_id: "", 
    currency: "CAD",
    password: "",
    company_id: 0,
    email: "",
    country_id: 0,
    address: "",
    city: "",
    zip_code: "",
    max_file_size: 0,
    max_storage_size: 0,
    storage_used: 0,
    last_payment_date: "",
    payment_data: {
      card_expiration: "MM/YY",
    },
    plan_data:{
      current_plan_type: "",
      current_plan_id: 0,
      current_plan_price: 0,
      current_active_until: "",
    },
    site_date: "",
    amount_paid: "",
    total_refunded: "", 
    deleted: false,
  };
  
  
  const [formValues, setFormValues] = useState(defaultValues)

  const handleInputChange = (e: { target: { name: any; value: any; }; }) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  /*=======================================
  GET INDUSTRIES LIST
  /companies/industries/get_list
  =======================================*/
  const [industries, setIndustries] = useState([]);
  useEffect(() => {
    axios.get(ProjectConfig.api_url + "/industries/list")
      .then((response) => {
        console.log("industries res", response.data)
        setIndustries(response.data.list)
      })
      .catch((err) => {
        console.log(err.response.data);
      });
  }, [])
  /*=======================================*/


  /*=========================================================
  PLAN LIST
  =========================================================*/
  const [planList, setPlanList] = useState<any []>([]);
  useEffect(() => {
    setLoading(true);

    axios.get(ProjectConfig.api_url+"/plans/list",
    {
      params: {
        full_list: 1,
        filters:{
          status: 1
        }
      }
    })
    .then((response) => {
        console.log("Plan List ",response.data); 
        setPlanList(response.data.list)
        setLoading(false)
    })
    .catch((err) => {
      console.log(err.response.data);
    })
    .finally(() => {
      setLoading(false)
    })
    
  }, [])


  /*=========================================================*/

  /*=========================================================
  GET ACTIVITY LOGS
  =========================================================*/
  const [activityLogs, setActivityLogs] = useState([]);
  useEffect(() => {
    axios.get(ProjectConfig.api_url + "/stores/activityLogs", {
      params: {
        store_id: store_id,
        full_list: true
      }, 
    })
      .then((response) => {
        console.log("activityLogs res", response.data)
        setActivityLogs(response.data.list)
      })
      .catch((err) => {
        console.log(err.response.data);
      });
  }, [])


  /*=========================================================
  GET STORE METRICS
  =========================================================*/
  const [storeMetrics, setStoreMetrics] = useState<any>({});
  useEffect(() => {
    axios.get(ProjectConfig.api_url + "/stores/metrics", {
      params: {
        store_id: store_id,
      },
    })
      .then((response) => {
        console.log("storeMetrics res", response.data)
        setStoreMetrics(response.data)
      })
      .catch((err) => {
        console.log(err.response.data);
      });
  }, [])



  /*=======================================
  SUBMIT FORM
  =======================================*/
  const handleSubmit = () => {
    // Validate max_storage_size
    const storageSize = parseFloat(formValues.max_storage_size);
    if (isNaN(storageSize) || storageSize < 0) {
      enqueueSnackbar('Please enter a valid number for Max Storage Size', { variant: 'error' });
      return;
    }

    setLoading(true);
    const body = new FormData();
    body.append("formValues", JSON.stringify(formValues));
    axios.post(ProjectConfig.api_url+"/stores/addedit", body,
      {
        headers: {
          // 
        },
      })
      .then((response) => {
        console.log(response.data);

        if(response.data.status === "success"){
          enqueueSnackbar('Success', { variant: 'success' });
          // navigate("/stores")
          navigate("/stores")

        }else{
          enqueueSnackbar(response.data.message, { variant: 'error' });
        }
          
      })
      .catch((err) => {
        console.log(err.response.data);
      })
      .finally(() => {
        setLoading(false)
      });
  };



  /*=======================================
  GET STORE DETAILS
  =======================================*/
  useEffect(() => {
    getDetails();

  }, [])

  const getDetails = async () => {
    axios.get(ProjectConfig.api_url + "/stores/details", {
      params: {
        store_id: store_id,
      },
    })
    .then((response) => {
      console.log("formValues res", response.data)
      setFormValues(response.data)
    })
    .catch((err) => {
      console.log(err.response.data);
    });
  }
  
  /*=======================================
  DELETE STORE
  =======================================*/
  const deleteStore = () => {
    setLoading(true)

    axios.post(ProjectConfig.api_url+"/stores/deleteStore", {
      store_id: store_id,
    })
    .then((response) => {
        console.log("Delete Store",response.data); 
        if(response.data.status === "success"){
          enqueueSnackbar('Success', { variant: 'success' });
          navigate("/stores")
        }else{
          enqueueSnackbar(response.data.message, { variant: 'error' });
        }
        setLoading(false)
    })
    .catch((err) => {
      console.log(err.response.data);
      setLoading(false)
    })
  }


  const [openRefunds, setOpenRefunds] = useState(false);
  const [openCreditCard, setOpenCreditCard] = useState(false);
  const [openPlanChanger, setOpenPlanChanger] = useState(false);
  

  return (
      <>
        
          <div className="store_info_section">
            <div className="store_info_box">
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell className="input_label">Company</TableCell>
                    <TableCell>
                      <TextField
                        name="company_name"
                        value={formValues.company_name}
                        onChange={handleInputChange}
                        fullWidth
                        size="small"
                      />
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell className="input_label">First Name</TableCell>
                    <TableCell>
                      <TextField
                        name="name"
                        value={formValues.name}
                        onChange={handleInputChange}
                        fullWidth
                        size="small"
                      />
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell className="input_label">Last Name</TableCell>
                    <TableCell>
                      <TextField
                        name="lastname"
                        value={formValues.lastname}
                        onChange={handleInputChange}
                        fullWidth
                        size="small"
                      />
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell className="input_label">Phone</TableCell>
                    <TableCell>
                      <TextField
                        name="phone"
                        value={formValues.phone}
                        onChange={handleInputChange}
                        fullWidth
                        size="small"
                      />
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell className="input_label">Industry</TableCell>
                    <TableCell>
                      <FormControl fullWidth>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={formValues.industry_id}
                          name="industry_id"
                          onChange={handleInputChange}
                          size="small"
                          fullWidth 
                        >
                          {industries.map((industry: any, index: number) => (
                            <MenuItem value={industry.value} key={index}>{industry.label}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell className="input_label">Currency</TableCell>
                    <TableCell>
                      <FormControl fullWidth>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={formValues.currency}
                          name="currency"
                          onChange={handleInputChange}
                          size="small"
                          fullWidth
                        >
                          <MenuItem value={"CAD"}>CAD</MenuItem>
                          <MenuItem value={"USD"}>USD</MenuItem>
                        </Select>
                      </FormControl>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell className="input_label">Password</TableCell>
                    <TableCell>
                      <TextField
                        name="password"
                        value={formValues.password}
                        onChange={handleInputChange}
                        fullWidth
                        size="small"
                      />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </div>

            <div className="store_info_box">
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell className="input_label">Email</TableCell>
                    <TableCell>
                      <TextField
                        name="email"
                        value={formValues.email}
                        onChange={handleInputChange}
                        fullWidth
                        size="small"
                      />
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell className="input_label">Country</TableCell>
                    <TableCell>

                    <FormControl fullWidth>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={formValues.country_id}
                        name="country_id"
                        onChange={handleInputChange}
                        size="small"
                        fullWidth
                      >
                        <MenuItem value={1}>Canada</MenuItem>
                        <MenuItem value={2}>United States</MenuItem>
                      </Select>
                    </FormControl>
                  
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell className="input_label">Address</TableCell>
                    <TableCell>
                      <TextField
                        name="address"
                        value={formValues.address}
                        onChange={handleInputChange}
                        fullWidth
                        size="small"
                      />
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell className="input_label">City</TableCell>
                    <TableCell>
                      <TextField
                        name="city"
                        value={formValues.city}
                        onChange={handleInputChange}
                        fullWidth
                        size="small"
                      />
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell className="input_label">Postal Code</TableCell>
                    <TableCell>
                      <TextField
                        name="zip_code"
                        fullWidth
                        size="small"
                        value={formValues.zip_code}
                        onChange={handleInputChange}
                      />
                    </TableCell>
                  </TableRow> 

                  <TableRow>
                    <TableCell className="input_label">Max File Size</TableCell>
                    <TableCell>
                      <TextField
                        name="max_file_size"
                        fullWidth
                        size="small"
                        value={formValues.max_file_size}
                        onChange={handleInputChange}
                        autoComplete="off"
                        type="number"
                      />
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell className="input_label">Max Storage Size</TableCell>
                    <TableCell>
                      <TextField
                        name="max_storage_size"
                        fullWidth
                        size="small"
                        value={formValues.max_storage_size}
                        onChange={handleInputChange}
                        autoComplete="off"
                        type="number"
                      />
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell className="input_label">Storage Used</TableCell>
                    <TableCell>
                      <Tooltip title={`${formValues.storage_used} / ${formValues.max_storage_size} MB`} placement="top">
                        <div style={{ position: 'relative' }}>
                          <BorderLinearProgress
                            variant="determinate"
                            value={formValues.max_storage_size ? (formValues.storage_used / formValues.max_storage_size) * 100 : 0}
                          />
                          <div style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            color: '#ccc'
                          }}>
                            {formValues.max_storage_size ? `${Math.round((formValues.storage_used / formValues.max_storage_size) * 100)}%` : '-'}
                          </div>
                        </div>
                      </Tooltip>
                    </TableCell>
                  </TableRow>

                </TableBody>
              </Table>
            </div>
          </div>

          <h2 className="section_title">Package</h2>
          <div className="store_info_section">
            <div className="store_info_box">
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell className="input_label">Plan</TableCell>
                    <TableCell>
                      <FormControl fullWidth>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={formValues.plan_data.current_plan_id}
                          name="current_plan_id"
                          onClick={() => setOpenPlanChanger(true)}
                          disabled
                          // onChange={(e) => {
                          //   setFormValues({
                          //     ...formValues,
                          //     current_plan_id: e.target.value,
                          //   });
                          // }}
                          size="small"
                          fullWidth
                          
                        >
                          {planList.map((plan: any, index: number) => (
                            <MenuItem value={plan.plan_id} key={index}>{plan.plan_name}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell className="input_label">Next Billing Date</TableCell>
                    <TableCell>
                      <TextField
                        name="active_until"
                        value={formValues.plan_data.current_active_until}
                        onChange={handleInputChange}
                        fullWidth
                        size="small"
                        inputProps={{ readOnly: true }}
                        sx={{
                          "& fieldset": { border: 'none' },
                        }}
                      />
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell className="input_label">Last Payment Date</TableCell>
                    <TableCell>
                      <TextField
                        name="last_payment_date"
                        value={formValues.last_payment_date}
                        onChange={handleInputChange}
                        fullWidth
                        size="small"
                        inputProps={{ readOnly: true }}
                        sx={{
                          "& fieldset": { border: 'none' },
                        }}
                      />
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell className="input_label">Card Expiration</TableCell>
                    <TableCell>
                      <TextField
                        name="card_expiration_date"
                        inputProps={{ readOnly: true }}
                        sx={{
                          "& fieldset": { border: 'none' },
                        }}
                        

                        value={formValues.payment_data.card_expiration}
                        onChange={(e) => {
                          setFormValues({
                            ...formValues,
                            payment_data: {
                              ...formValues.payment_data,
                              card_expiration: e.target.value,
                            },
                          });
                        }}
                        fullWidth
                        size="small"
                      />
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell className="input_label">Account Management</TableCell>
                    <TableCell>
                      {!formValues.deleted ? (
                        <Button
                          variant="contained"
                          className="button_2"
                          color="error"
                          size="small"
                          onClick={() => setOpenDeleteAlert(true)}
                        >
                          Delete Account
                        </Button>
                      ) : (
                        <span className="account_deleted_label">Account Deleted</span>
                      )}
                    </TableCell>
                  </TableRow>

                </TableBody>
              </Table>
            </div>

            <div className="store_info_box">
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell className="input_label">Plan Type</TableCell>
                    <TableCell>
                      <FormControl fullWidth>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={formValues.plan_data.current_plan_type}
                          name="current_plan_type"

                          size="small"
                          fullWidth
                          inputProps={{ readOnly: true }}
                          className="input_readonly"
                        >
                          <MenuItem value={"monthly"}>Monthly</MenuItem>
                          <MenuItem value={"yearly"}>Yearly</MenuItem>
                        </Select>
                      </FormControl>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell className="input_label">Plan Price</TableCell>
                    <TableCell>
                      <TextField
                        name="plan_price"
                        value={formValues.plan_data.current_plan_price}
                        
                        fullWidth
                        size="small"
                        sx={{
                          "& fieldset": { border: 'none' },
                        }}

                      />
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell className="input_label">Signup Date</TableCell>
                    <TableCell>
                      <TextField
                        name="created_at"
                        value={formValues.created_at}
                        onChange={handleInputChange}
                        fullWidth
                        size="small"
                        inputProps={{ readOnly: true }}
                        sx={{
                          "& fieldset": { border: 'none' },
                        }}
                      /> 
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell className="input_label">Lifetime Value</TableCell>
                    <TableCell>
                      <TextField
                        name="amount_paid"
                        value={"$" + Number(formValues.amount_paid).toLocaleString()}
                        onChange={handleInputChange}
                        fullWidth
                        size="small"
                        inputProps={{ readOnly: true }}
                        sx={{
                          "& fieldset": { border: 'none' },
                        }}
                      />
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell className="input_label">Refund</TableCell>
                    <TableCell>
                      <TextField
                        name="refund"
                        value={"$" + Number(formValues.total_refunded).toLocaleString()}
                        onChange={handleInputChange}

                        
                        size="small"
                        inputProps={{ readOnly: true }}
                        sx={{
                          "& fieldset": { border: 'none' },
                        }}
                      />

                      <Button
                        variant="contained"
                        className="button_2"
                        color="error"
                        size="small"
                        onClick={() => setOpenRefunds(true)}
                      >
                        Refund
                      </Button>
                    </TableCell>
                  </TableRow>
                  
                </TableBody>
              </Table>
            </div>
          </div>

          <div id="activity_logs">
            <h2 className="section_title">Activity Logs</h2>
            <Table>
              <TableBody>
                {activityLogs.map((log: any, index: number) => (
                  <TableRow key={index}>
                    <TableCell>{log.log}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>

          <div id="store_metrics">
            <h2 className="section_title">Metrics</h2>
            <div className="store_info_section">
              <div className="store_info_box">
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell className="input_label">Total Sales Amount</TableCell>
                      <TableCell>${storeMetrics.total_sales}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className="input_label">Number Of Orders</TableCell>
                      <TableCell>{storeMetrics.total_orders}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className="input_label">Product Page To Successful Checkout</TableCell>
                      <TableCell>{storeMetrics.orders_completed_percentage}%</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </div>

              <div className="store_info_box">
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell className="input_label">YTD Sales</TableCell>
                      <TableCell>${storeMetrics.ytd_sales}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className="input_label">Average Order Value (AOV)</TableCell>
                      <TableCell>${storeMetrics.avg_order_value}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className="input_label">Payment Success Rate (PSR)</TableCell>
                      <TableCell>{storeMetrics.payment_success_rate}%</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className="input_label">Payment Decline Rate (PDR)</TableCell>
                      <TableCell>{storeMetrics.payment_decline_rate}%</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </div>
            </div>
          </div>
          


          

          <div className="dialogButtons">

            <Button 
              onClick={handleSubmit} 
              variant="contained" 
              className="button_1"
            >
              Save Account Details
            </Button>

            <Button
                variant="contained"
                color="secondary"
                className="button_secondary"
                onClick={() => navigate("/stores")}
            >
              Cancel Changes
            </Button>

            
          </div>

          <AlertDialog
            open={openDeleteAlert}
            setOpen={setOpenDeleteAlert}
            send={deleteStore}
            input_confirm_required={true}
            input_confirm_required_text="Delete Account"
            input_confirm_required_label="Type 'Delete Account' to confirm"
            button_color="error"
            accept_text="Delete"
            cancel_text="No, Don't Delete"
          />

          <RefundsList
            open={openRefunds}
            setOpen={setOpenRefunds}
            store_id={store_id}
            setLoading={setLoading}
            getDetails={getDetails}
          />

          {/* <AddCreditCard
            open={openCreditCard}
            setOpen={setOpenCreditCard}
            store_id={store_id}
          /> */}

          <PlanChanger
            open={openPlanChanger}
            setOpen={setOpenPlanChanger}
            store_id={store_id}
            plan_data={formValues.plan_data}
            getDetails={getDetails}
          />
          
      </>
  );
}
