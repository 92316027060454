import React, { useState, useRef } from 'react';
import { Button } from "@mui/material";
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import { FaCamera } from "react-icons/fa";


interface ImageUploaderProps {
    image: string;
    setImage: (image: string) => void;
    setFiles: (file: any) => void;
    className?: string;
    disabled?: boolean;
    acceptedFormats?: string[]; // Defines accepted file formats
    maxSizeInMB?: number; // New prop for maximum file size in MB
    onClear?: () => void; // Optional callback when image is cleared
}

export default function ImageUploader({ image, setImage, setFiles, className, disabled, acceptedFormats, maxSizeInMB, onClear }: ImageUploaderProps) {
    const fileInputRef = useRef<HTMLInputElement>(null);
    
    // Function to validate file format
    const isValidFileFormat = (file: File): boolean => {
        if (!acceptedFormats || acceptedFormats.length === 0) return true;
        
        const fileExtension = file.name.split('.').pop()?.toLowerCase();
        return fileExtension ? acceptedFormats.includes(fileExtension) : false;
    };

    // Function to validate file size
    const isValidFileSize = (file: File): boolean => {
        if (!maxSizeInMB) return true;
        
        const fileSizeInMB = file.size / (1024 * 1024);
        return fileSizeInMB <= maxSizeInMB;
    };

    // Function to clear the file input value
    const clearFileInput = () => {
        if (fileInputRef.current) {
            fileInputRef.current.value = '';
            
            // Call the optional callback if provided
            if (onClear) {
                onClear();
            }
        }
    };

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;
        if (files && files[0]) {
            // Check file format
            if (!isValidFileFormat(files[0])) {
                alert(`Invalid file format. Please upload ${acceptedFormats?.join(', ')} files only.`);
                return;
            }
            
            // Check file size
            if (!isValidFileSize(files[0])) {
                alert(`File is too large. Maximum size is ${maxSizeInMB}MB.`);
                return;
            }
            
            setFiles(files[0]);
            const fileArray = Array.from(files).map((file: any) => URL.createObjectURL(file));
            setImage(fileArray[0]);
        }
    };

    const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        const files = e.dataTransfer.files;
        if (files && files[0]) {
            // Check file format
            if (!isValidFileFormat(files[0])) {
                alert(`Invalid file format. Please upload ${acceptedFormats?.join(', ')} files only.`);
                return;
            }
            
            // Check file size
            if (!isValidFileSize(files[0])) {
                alert(`File is too large. Maximum size is ${maxSizeInMB}MB.`);
                return;
            }
            
            setFiles(files[0]);
            const fileArray = Array.from(files).map((file: any) => URL.createObjectURL(file));
            setImage(fileArray[0]);
        }
        e.currentTarget.classList.remove("dropzone_active");
    };

    // Create accept attribute string for input if acceptedFormats is provided
    const getAcceptString = (): string => {
        if (!acceptedFormats || acceptedFormats.length === 0) return '';
        return acceptedFormats.map(format => `.${format}`).join(',');
    };

    return (
        <div className={`drawerImagesContainerDiv ${className}`}
            onDrop={handleDrop}
            onDragOver={(e) => {
                e.preventDefault();
                e.currentTarget.classList.add("dropzone_active");
            }}
            onDragLeave={(e) => {
                e.preventDefault();
                e.currentTarget.classList.remove("dropzone_active");
            }}
        >
            {image && <img src={image} alt="Company Logo" />}
            <label id="dropFileDiv" className={image ? 'showOnlyOnHover' : ''} style={disabled ? { display: 'none' } : {}}>
                <Button
                    aria-label="delete"
                    size="small"
                    className="addFile_button"
                    component="span"
                    startIcon={<CameraAltIcon />}
                >
                    <div className='imageUploader_label'>Upload Company Logo</div>
                    <input
                        type="file"
                        multiple
                        hidden
                        ref={fileInputRef}
                        onChange={handleFileChange}
                        accept={getAcceptString()}
                    />
                </Button>
            </label>
        </div>
    );
}
