import { Button, Divider, InputLabel, TextField, IconButton } from "@mui/material";
import { FaGoogle } from "react-icons/fa";
import { FaWebflow } from "react-icons/fa6";
import { ProjectConfig } from "Global";
import { useState, useCallback, useEffect } from "react";
import { useSnackbar } from 'notistack';
import { ApiRequest } from "GlobalFunctions";
import { signInWithPopup } from 'firebase/auth';
import { auth, googleProvider } from './Firebase';
import ic_webflow from "../../icons/ic_webflow.svg";
import ic_google from "../../icons/ic_google.svg";
import { CiLock } from "react-icons/ci";
import { PiEyeSlashLight } from "react-icons/pi";
import { PiEyeLight } from "react-icons/pi";

  const loginDataDefault = {
    email: '',
    password: '',
  }

export default function Signin(props: any) {
    const { enqueueSnackbar } = useSnackbar();
    const [authPopup, setAuthPopup] = useState<Window | null>(null);
    const [loadingButton, setLoadingButton] = useState(false);
    const [loginData, setLoginData] = useState(loginDataDefault);
    const [showPassword, setShowPassword] = useState(false);

    // Handle messages from the popup window
    const handleAuthMessage = useCallback((event: MessageEvent) => {
        // You might want to add origin validation in production
        // if (event.origin !== "https://api.test.com") return;
        
        const data = event.data;
        console.log("Received auth data:", data);
        
        if (data && data.status === 'success' && data.ltkn) {
            // Store the auth token
            localStorage.setItem('ltkn', data.ltkn);
            localStorage.setItem('store_id', data.store_id);
            localStorage.setItem('role_id', data.role_id);
            
            // Notify user
            enqueueSnackbar('Sign in successful! Redirecting...', { 
                variant: 'success',
                autoHideDuration: 3000
            });
            
            // Redirect or update UI state as needed
            setTimeout(() => {
                // reload
                window.location.reload();
            }, 2000);
        } else if (data && data.status === 'error') {
            enqueueSnackbar(data.message || 'Authentication failed', { 
                variant: 'error',
                autoHideDuration: 5000
            });
        }
    }, [enqueueSnackbar]);

    // Setup and cleanup the message listener
    useEffect(() => {
        window.addEventListener('message', handleAuthMessage);
        
        return () => {
            window.removeEventListener('message', handleAuthMessage);
            
            // Close the popup if it's still open when component unmounts
            if (authPopup && !authPopup.closed) {
                authPopup.close();
            }
        };
    }, [handleAuthMessage, authPopup]);

    const openWebflowModal = () => {
        let url = `https://webflow.com/oauth/authorize?response_type=code`
        url += `&client_id=${ProjectConfig.WEBFLOW_CLIENT_ID}`
        url += `&redirect_uri=`
        url += `&state=signin`
        url += `&scope=assets%3Aread%20assets%3Awrite%20authorized_user%3Aread%20cms%3Aread%20cms%3Awrite%20custom_code%3Aread%20custom_code%3Awrite%20forms%3Aread%20forms%3Awrite%20pages%3Aread%20pages%3Awrite%20sites%3Aread%20sites%3Awrite%20ecommerce%3Aread%20ecommerce%3Awrite%20users%3Aread%20users%3Awrite`;
    
        const popup = window.open(url, '_blank', 'location=yes,scrollbars=yes,status=yes');
        
        setAuthPopup(popup);
    
        if (popup) {
            const popupCheckInterval = setInterval(() => {
                if (popup.closed) {
                    clearInterval(popupCheckInterval);
                    setAuthPopup(null);
                }
            }, 500);
        }
    };

    const signInWithGoogle = async () => {
        try {
          setLoadingButton(true);
          const result = await signInWithPopup(auth, googleProvider);
          const user = result.user;
          
          // Aquí deberías hacer una llamada a tu API para registrar/autenticar al usuario
          // usando los datos de Google (email, nombre, etc.)

            ApiRequest({
                url: '/users/google-auth',
                method: 'POST',
                body: {
                    email: user.email,
                    name: user.displayName,
                    google_id: user.uid
                },
                setResponse: (response: any) => {
                    if(response.status === "success"){
                        enqueueSnackbar(response.message, { variant: 'success' });
                        const user_info = response.user_info;
                        
                        localStorage.setItem('ltkn', user_info.ltkn);
                        localStorage.setItem('role_id', user_info.role_id);
                        localStorage.setItem('store_id', user_info.store_id);
                        
                        window.location.href = '/';
                      } else {
                        enqueueSnackbar(response.message, { variant: 'error' });
                      }
                      setLoadingButton(false);
                }
            });

          
        } catch (error) {
          console.error(error);
          enqueueSnackbar("Error al iniciar sesión con Google", { variant: 'error' });
          setLoadingButton(false);
        }
      };


      const sign_in = () => {

        ApiRequest({
            url: '/users/auth',
            method: 'GET',
            query: {
                email: loginData.email,
                password: loginData.password,
            },
            setResponse: (response: any) => {
                console.log("RESPONSE LOGIN",response);
                if(response.status === "success"){
                  
                  enqueueSnackbar(response.message, { variant: 'success' });
                  const user_info = response.user_info;
                  console.log(user_info);
      
                  localStorage.setItem('ltkn', user_info.ltkn);
                  localStorage.setItem('role_id', user_info.role_id);
                  
                  if(user_info.role_id === 1){
                    if(user_info.store_id){
                      localStorage.setItem('store_id', user_info.store_id);
                      window.location.reload();
                    } else {
                      window.location.href = '/webflow?new_store';
                    }
                  }
                  else{
                    window.location.reload();
                  }
      
      
                  
                }
                else
                {
                  enqueueSnackbar(response.message, { variant: 'error' });
                  setLoadingButton(false);
                }
            }
        });



      
        }  

    const handleTogglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    return (
        <div className="signin_container">
            <div className="signin_with_buttons">
            <Button
                variant="outlined"
                onClick={openWebflowModal}
                color="info"
                className="login_w_button"
                startIcon={<img src={ic_webflow} alt="Webflow" />}
                fullWidth
            >
                Sign In with Webflow
            </Button>

            <Button
                variant="outlined"
                onClick={() => {
                    signInWithGoogle();
                }}
                color="info"
                className="login_w_button"
                startIcon={<img src={ic_google} alt="Google" />}
                fullWidth
            >
                Sign In with Google
            </Button>

                                            
            </div>

            <Divider/>
            
            <form className="signin_form" onSubmit={(e) => {
                e.preventDefault();
                sign_in();
            }}>
                <div className="textfield_wrapper">
                    <InputLabel className="external_label">Email</InputLabel>
                    <TextField
                        variant="outlined"
                        value={loginData.email}
                        onChange={(e) => setLoginData({ ...loginData, email: e.target.value })}
                        fullWidth
                    />
                </div>

                <div className="textfield_wrapper">
                    <InputLabel className="external_label">Password</InputLabel>
                    <TextField
                        variant="outlined"
                        value={loginData.password}
                        onChange={(e) => setLoginData({ ...loginData, password: e.target.value })}
                        type={showPassword ? "text" : "password"}
                        fullWidth
                        sx={{
                          '& .MuiInputBase-input': {
                            padding: '12px 0 !important',
                          },
                        }}
                        InputProps={{
                            startAdornment: <CiLock size={24} color="#B9B9B9" style={{marginRight: 10}} />,
                            endAdornment: (
                              <IconButton 
                                onClick={handleTogglePasswordVisibility}
                                edge="end"
                                className="show_password_button"
                              >
                                {showPassword ? 
                                  <PiEyeLight size={24} color="#B9B9B9" /> : 
                                  <PiEyeSlashLight size={24} color="#B9B9B9"/>
                                }
                              </IconButton>
                            )
                        }}
                    />
                </div>
                <Button 
                    variant="contained" 
                    color="primary"
                    fullWidth
                    type="submit"
                >
                    Sign In
                </Button> 
            </form>
        </div>
    )
}